import React, {Component} from "react";
import "../css/credits.css";

class Credits extends Component {
    render() {
      return (

        <div className="footer_credits_container">
          <div className="footer_credits">
            <a className="credits_call" href="https://leadfy-imob.com.br/" alt="Teste o Leadfy">
              <span>Feito com <b>Leadfy</b></span> 
              <img className="credits_logo" src={`${window.LEADFY_CB_ENV.appBaseUrl}/images/leadfy.png`} alt="Logo do Leadfy" />
            </a>
          </div>
        </div>
      )
    }
  }
  
  export default Credits;