import React, { Component } from 'react';
import ChatBot from '../react-simple-chatbot/lib/index';
import { ThemeProvider } from 'styled-components';
//import { steps } from './steps';
import Header from './header';
import '../css/chat.css';
import {botPhoto} from './utils'
import BrushSteps from "./brush_steps"
// import { publishBotFillEvent } from './botEvents';

const theme = {
  background: window.leadfyChatbot.bgColor ? window.leadfyChatbot.bgColor : '#F8F8F8',
  headerBgColor: 'transparent',
  headerFontColor: '#fff',
  headerFontSize: '20px',
  botBubbleColor: '#FFFFFF',
  botFontColor: '#2C2C2C',
  userBubbleColor: window.leadfyChatbot.primaryColor ? window.leadfyChatbot.primaryColor : '#3AC9C8',
  userFontColor: '#FFFFFF',
}


class SimpleForm extends Component {
  state = {
    opened: window.leadfyChatbot.fullScreen,
    steps: new BrushSteps().brush(window.leadfyChatbot.stepss),
    balloonMsgs: window.leadfyChatbot.balloonMsgs,
    fullScreen: window.leadfyChatbot.fullScreen,
    avatarName: window.leadfyChatbot.avatarName,
    avatarImageUrl: window.leadfyChatbot.avatarImageUrl,
    botPosition: window.leadfyChatbot.botPosition
  }
  
  componentDidMount() {
    this.handleData.bind(this);
    this.handleEnd.bind(this);
    this.sendMessage.bind(this);
    this.changeColors();
  }

  handleData = ( data ) => { // , publishBotFillEvent

    this.sendMessage({
      var_name: data.var_name,
      value: data.value
    })
  }

  handleEnd = ({renderedSteps, steps, values}) => { // , publishBotFinishEvent
    // publishBotFinishEvent(values.slice(1))
    this.sendMessage({is_end: true})
  }

  sendMessage = (body) => {
    console.log('sendMessage: ', body)
    fetch(
      `${window.LEADFY_CB_ENV.apiBaseUrl}/message`,
      {
        method: "POST",
        body: JSON.stringify({
            extra_data: this.extra_data(),
            chatbot: window.leadfyChatbot.chatbot,
            session: window.leadfyChatbot.session,
            ...body
          }),
        headers: {'Content-Type': 'application/json'},
      }
    ).then((response) => response.json())
    .then((result) => {
      // console.log('Success:', result);
    })
    .catch(err => console.error(err));

    return true;
  }

  extra_data = () => {
    console.log('extra_data: ', window.lfCbSubmitExtra())
    return (typeof window.lfCbSubmitExtra === 'function') ? window.lfCbSubmitExtra() : null
  }

  toggleFloating = () => {
    if (this.state.opened === true && !this.state.fullScreen)
      this.setState({opened: false})
    else
      this.setState({opened: true})
  }

  footerStyle = () => {
    
    return this.state.fullScreen 
    ? {
      bottom: '0',
    } 
    : {}
  }

  floatingStyle = () => {
    if(this.state.fullScreen){
      return {
        left: '0',
        bottom: '0'
      }
    }
    if(this.state.botPosition === 'right'){
      return {
        transformOrigin: 'bottom right'
      } //right position is the natural state from "simple chatbot"
    }
    else{
      return { //Leadfy standard
        left: '30px'
      }
    }
  }

  changeColors() {
    if(window.leadfyChatbot.primaryColor) {
      const primaryColor = window.leadfyChatbot.primaryColor
      document.getElementById("chatbot-root").style.setProperty("--bot-primary-color", primaryColor)
    }
    if(window.leadfyChatbot.bgColor){
      const bgColor = window.leadfyChatbot.bgColor
      document.getElementById("chatbot-root").style.setProperty("--bot-bg-color", bgColor)
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          inviteMsgs={this.state.balloonMsgs}
          handleData={this.handleData}
          handleEnd={this.handleEnd}
          placeholder="Digite sua resposta..."
          botAvatar={botPhoto}
          opened={this.state.opened} 
          fullScreen={this.state.fullScreen}
          headerComponent={<Header toggleFloating={this.toggleFloating}/>}
          toggleFloating={this.toggleFloating}
          floating={true}
          enableSmoothScroll={true}
          floatingStyle={this.floatingStyle()}
          width={this.state.fullScreen ? '100%' : '420px'}
          style={this.state.fullScreen ? {height: '100%'} : {height: 540}}
          footerStyle={this.footerStyle()}
          botPosition={this.state.botPosition}
          steps={this.state.steps}
        />
      </ThemeProvider>
    );
  }
}

export default SimpleForm
